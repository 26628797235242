class Config {
  YUYAN = 'fr'
  TITLE = 'BubbleTeaTime'
  DOMAIN = 'https://admin.bubbleteatime.fr'
  TIMEOUT = 30000
  TIMEOUTUPLOAD = 1800000
  TUPIANBASE = this.DOMAIN + '/public/'
}

module.exports = new Config()
