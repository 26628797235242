<template>
  <div class="mobile-menu-wrapper">
    <div class="mobile-menu-overlay"></div>
    <span class="mobile-menu-close"><i class="close-icon"></i></span>
    <div class="mobile-menu-container scrollable">
      <div class="tab">
        <ul class="nav nav-tabs" role="tablist">
          <li class="nav-item">
            <span @click="tab = 1" class="nav-link active">
              {{ $store.state.yuyanbao.caidan }}
            </span>
          </li>
          <li class="nav-item">
            <span @click="tab = 2" class="nav-link">
              {{ $store.state.yuyanbao.fenlei }}
            </span>
          </li>
        </ul>
      </div>
      <div class="tab-content">
        <div
          class="tab-pane"
          :class="{ active: tab === 1 }"
          @click="tap_tabbar"
        >
          <ul class="mobile-menu">
            <li>
              <router-link to="/">
                {{ $store.state.yuyanbao.shouye }}
              </router-link>
            </li>
            <li>
              <router-link to="/aboutus">
                {{ $store.state.yuyanbao.guanyuwomen }}
              </router-link>
            </li>
            <li>
              <router-link to="/contactus">
                {{ $store.state.yuyanbao.lianxiwomen }}
              </router-link>
            </li>
          </ul>
        </div>
        <div
          class="tab-pane"
          :class="{ active: tab === 2 }"
          @click="tap_tabbar"
        >
          <ul class="mobile-menu">
            <li
              class="show"
              v-for="(item, index) in shangpinfenleis"
              :key="index"
            >
              <router-link
                :to="{ path: '/produits', query: { ukey: item.ukey } }"
                style="color: #eee"
              >
                {{ duoyuyan(item) }}
              </router-link>
              <ul
                style="display: block"
                v-if="item.children && item.children.length > 0"
              >
                <li
                  v-for="(item1, index1) in item.children"
                  :key="index1"
                  class="show"
                >
                  <router-link
                    :to="{ path: '/products', query: { ukey: item1.ukey } }"
                    style="color: #eee"
                  >
                    {{ duoyuyan(item1) }}
                  </router-link>
                  <ul v-if="item1.children && item1.children.length > 0">
                    <li v-for="(item2, index2) in item1.children" :key="index2">
                      <router-link
                        :to="{ path: '/products', query: { ukey: item2.ukey } }"
                        style="color: #eee"
                      >
                        {{ duoyuyan(item2) }}
                      </router-link>
                    </li>
                  </ul>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getShangPinFenLei } from "@/api/shanpin_fenlei";

export default {
  data() {
    return {
      shangpinfenleis: [],
      tab: 1,
    };
  },
  mounted() {
    this.get_category_http();
  },
  methods: {
    tap_tabbar() {
      document.getElementsByClassName("mobile-menu-close")[0].click();
    },
    get_category_http() {
      getShangPinFenLei().then((data) => {
        if (data.status === 1) {
          const dg = (data) => {
            for (let i = 0, len = data.length; i < len; i += 1) {
              this.shangpinfenleis.push(data[i]);
              dg(data[i].children);
            }
          };
          dg(data.data);
        }
      });
    },
  },
};
</script>

<style></style>
