<template>
  <div class="sticky-footer sticky-content fix-bottom fixed">
    <router-link to="/" class="sticky-link active">
      <i class="w-icon-home"></i>
      <p>{{ $store.state.yuyanbao.shouye }}</p>
    </router-link>
    <router-link :to="{ path: 'produits' }" class="sticky-link">
      <i class="w-icon-category"></i>
      <p>{{ $store.state.yuyanbao.fenlei }}</p>
    </router-link>
    <a class="sticky-link" :href="'tel:' + $store.state.shezhi.whatsapp">
      <i class="w-icon-call"></i>
      <p>{{ $store.state.yuyanbao.dianhua }}</p>
    </a>
    <router-link to="/contactus" class="sticky-link search-toggle">
      <i class="w-icon-map-marker"></i>
      <p>{{ $store.state.yuyanbao.cangku }}</p>
    </router-link>
  </div>
</template>

<script>
import { getFuWu } from "@/api/fuwu";

export default {
  data() {
    return {
      fuwu: [],
    };
  },
  mounted() {
    this.get_fuwu_http();
  },
  methods: {
    get_fuwu_http() {
      getFuWu().then((data) => {
        if (data.status === "SUCCESSED") this.fuwu = data.data;
      });
    },
    tap_tabbar() {
      document.getElementById("tabbarfuwu").click();
    },
  },
};
</script>

<style></style>
