<template>
  <header class="header">
    <div class="header-top">
      <div class="container">
        <div class="header-left">
          <p class="welcome-msg" style="text-transform: none">
            <span style="margin-right: 20px">
              {{ $store.state.shezhi.dianhua }}
            </span>
            <span style="margin-right: 20px">
              {{ $store.state.shezhi.youxiang }}
            </span>
            <span>
              {{ $store.state.shezhi.yingyeshijian }}
            </span>
          </p>
        </div>
        <div class="header-right pr-0">
          <!-- <div class="dropdown">
            <a href="javascript:void(0);" style="text-transform: uppercase">
              <img
                :src="$tupianbase + 'system/' + $store.state.yuyan + '.webp'"
                width="14"
                height="8"
                class="dropdown-image"
              />
              {{ $store.state.yuyan }}
            </a>
            <div class="dropdown-box">
              <a
                href="javascript:void(0);"
                v-for="(item, index) in $store.state.yuyans"
                :key="index"
                style="text-transform: uppercase"
                @click="set_yuyan(item.jian)"
              >
                <img
                  :src="$tupianbase + 'system/' + item.jian + '.webp'"
                  width="14"
                  height="8"
                  class="dropdown-image"
                />
                {{ item.jian }}
              </a>
            </div>
          </div> -->
          <!-- <span class="divider d-lg-show"></span> -->
          <router-link
            to="/account"
            class="d-lg-show"
            v-if="$store.state.login === 1"
          >
            {{ $store.state.yuyanbao.wodezhanghu }}
          </router-link>
          <template v-if="$store.state.login === 0">
            <router-link to="/login" class="d-lg-show">
              <i class="w-icon-account"></i>
              {{ $store.state.yuyanbao.denglu }}
            </router-link>
            <span class="delimiter d-lg-show">/</span>
            <router-link to="/login" class="ml-0 d-lg-show">
              {{ $store.state.yuyanbao.zhuce }}
            </router-link>
          </template>
        </div>
      </div>
    </div>
    <div class="header-middle">
      <div class="container">
        <div class="header-left mr-md-4">
          <a
            href="#"
            class="mobile-menu-toggle w-icon-hamburger"
            aria-label="menu-toggle"
          >
          </a>
          <router-link to="/" class="logo ml-lg-0">
            <img :src="$store.state.shezhi.logo" width="144" height="45" />
          </router-link>
          <form
            class="header-search hs-expanded hs-round d-none d-md-flex input-wrapper"
          >
            <input
              id="myheadersearch"
              type="text"
              class="form-control"
              :placeholder="$store.state.yuyanbao.sousuo"
              required
              v-model="search"
              @keyup.enter="search_http"
              style="border: 2px solid #fe7c00"
            />
            <button
              class="btn btn-search"
              @click="search_http"
              style="border: 2px solid #fe7c00; border-left: none"
            >
              <i class="w-icon-search"></i>
            </button>
          </form>
        </div>
        <div class="header-right ml-4">
          <div class="header-call d-xs-show d-lg-flex align-items-center">
            <a
              :href="'tel:' + $store.state.shezhi.whatsapp"
              class="w-icon-call"
            ></a>
            <div class="call-info d-xl-show">
              <h4
                class="chat font-weight-normal font-size-md text-normal ls-normal text-light mb-0"
              >
                <a
                  :href="'tel:' + $store.state.shezhi.whatsapp"
                  class="text-capitalize"
                >
                  Whatsapp
                </a>
              </h4>
              <a
                :href="'tel:' + $store.state.shezhi.whatsapp"
                class="phone-number font-weight-bolder ls-50"
              >
                {{ $store.state.shezhi.whatsapp }}
              </a>
            </div>
          </div>
          <!-- <div class="dropdown cart-dropdown cart-offcanvas mr-0 mr-lg-2 ml-4">
            <div class="cart-overlay"></div>
            <router-link :to="{ path: '/cart' }" class="label-down link">
              <i class="w-icon-cart">
                <span class="cart-count">2</span>
              </i>
            </router-link>
          </div> -->
        </div>
      </div>
    </div>
    <div
      class="header-bottom sticky-content fix-top sticky-header has-dropdown"
      style="background-color: #333"
    >
      <div class="container">
        <div class="inner-wrap">
          <div class="header-left flex-1">
            <div
              class="dropdown category-dropdown has-border"
              data-visible="true"
            >
              <a
                role="button"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="true"
                data-display="static"
                style="background-color: #fe7c00; color: white"
              >
                <i class="w-icon-category"></i>
                <span>{{ $store.state.yuyanbao.quanbufenlei }} </span>
              </a>
              <div class="dropdown-box">
                <ul class="menu vertical-menu category-menu">
                  <li v-for="(item, index) in categorys" :key="index">
                    <router-link
                      :to="{ path: '/produits', query: { ukey: item.ukey } }"
                    >
                      {{ duoyuyan(item) }}
                    </router-link>
                    <ul
                      class="megamenu"
                      v-if="item.children && item.children.length > 0"
                    >
                      <li>
                        <h4
                          class="menu-title"
                          style="cursor: pointer"
                          @click="
                            $router.push({
                              path: '/produits',
                              query: {
                                ukey: item.ukey,
                              },
                            })
                          "
                        >
                          {{ duoyuyan(item) }}
                        </h4>
                        <hr class="divider" />
                        <ul>
                          <li
                            v-for="(item1, index1) in item.children"
                            :key="index1"
                          >
                            <router-link
                              :to="{
                                path: '/produits',
                                query: { ukey: item1.ukey },
                              }"
                            >
                              {{ duoyuyan(item1) }}
                            </router-link>
                          </li>
                        </ul>
                      </li>
                      <li></li>
                    </ul>
                  </li>
                </ul>
              </div>
            </div>
            <nav class="main-nav">
              <ul class="menu active-underline">
                <li>
                  <router-link
                    to="/"
                    :style="{
                      color: $route.name === 'Index' ? 'white' : '#ccc',
                    }"
                    >{{ $store.state.yuyanbao.shouye }}
                  </router-link>
                </li>
                <li>
                  <router-link
                    to="/aboutus"
                    class="d-lg-show"
                    :style="{
                      color: $route.name === 'AboutUs' ? 'white' : '#ccc',
                    }"
                  >
                    {{ $store.state.yuyanbao.guanyuwomen }}
                  </router-link>
                </li>
                <li>
                  <router-link
                    to="/contactus"
                    class="d-lg-show"
                    :style="{
                      color: $route.name === 'ContactUs' ? 'white' : '#ccc',
                    }"
                  >
                    {{ $store.state.yuyanbao.lianxiwomen }}
                  </router-link>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </div>
    <MobileMenu></MobileMenu>
  </header>
</template>

<script>
import MobileMenu from "@/components/MobileMenu.vue";
import { getShangPinFenLei } from "@/api/shanpin_fenlei";
import { getFuWu } from "@/api/fuwu";

export default {
  name: "MyHeader",
  components: {
    MobileMenu,
  },
  data() {
    return {
      categorys: [],
      services: [],
      search: "",
    };
  },
  mounted() {
    this.get_shangpin_fenlei();
    this.get_service_http();
  },
  methods: {
    set_yuyan(yuyan) {
      if (this.$store.state.yuyan === yuyan) return;
      if (yuyan.jian === this.$store.state.yuyan) return;
      this.$store.state.yuyan = yuyan.jian;
      localStorage.setItem("yuyan", yuyan.jian);
      window.location.reload();
    },
    get_service_http() {
      getFuWu().then((data) => {
        if (data.status === 1) this.services = data.data;
      });
    },
    get_shangpin_fenlei() {
      getShangPinFenLei({ fu: 0 }).then((data) => {
        if (data.status === 1) this.categorys = data.data;
      });
    },
    search_http() {
      if (this.search === "") return;
      this.$router.push({
        path: "/search",
        query: {
          search: this.search,
        },
      });
      if (this.$route.name === "Search") window.location.reload();
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.header-call {
  margin-right: 0;
}

.header-call::after {
  display: none;
}
</style>
